/**
 * Copyright 2024 SweetCode. All rights reserved.
 *
 * Load Twitter Ads functions
 * */

(function (wpm, $, undefined) {

	wpm.loadTwitterPixel = () => {

		try {
			wpmDataLayer.pixels.twitter.loaded = true

			// @formatter:off
			!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
			},s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
				a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');

			twq('config',wpmDataLayer.pixels.twitter.pixel_id);
			// @formatter:on

			// twq("track", "PageView")
		} catch (e) {
			console.error(e)
		}
	}

	wpm.twitterGetOrderContentIdsV1 = () => {
		let contentIds = []

		Object.values(wpmDataLayer.order.items).forEach(item => {

			if (wpmDataLayer?.shop?.variations_output && 0 !== item.variation_id) {
				contentIds.push(String(wpmDataLayer.products[item.variation_id].dyn_r_ids[wpmDataLayer.pixels.twitter.dynamic_remarketing.id_type]))
			} else {
				contentIds.push(String(wpmDataLayer.products[item.id].dyn_r_ids[wpmDataLayer.pixels.twitter.dynamic_remarketing.id_type]))
			}
		})

		return contentIds
	}

	wpm.twitterGetOrderContentIdsV2 = () => {

		let orderItems = []

		Object.values(wpmDataLayer.order.items).forEach(item => {

			let orderItem = {
				content_type : "product",
				content_name : item.name,
				content_price: item.price,
				num_items    : item.quantity,
				// content_group_id: null,
			}

			if (wpmDataLayer?.shop?.variations_output && 0 !== item.variation_id) {
				orderItem.content_id = wpmDataLayer.products[item.variation_id].dyn_r_ids[wpmDataLayer.pixels.twitter.dynamic_remarketing.id_type]
			} else {
				orderItem.content_id = wpmDataLayer.products[item.id].dyn_r_ids[wpmDataLayer.pixels.twitter.dynamic_remarketing.id_type]
			}


			orderItems.push(orderItem)
		})

		return orderItems
	}

}(window.wpm = window.wpm || {}, jQuery));
