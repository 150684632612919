/**
 * Copyright 2024 SweetCode. All rights reserved.
 *
 * Load Reddit functions
 * */

(function (wpm, $, undefined) {

	wpm.getRedditAdvertiserId = () => {
		return wpmDataLayer.pixels.reddit.advertiser_id
	}

	// https://redditinc.force.com/helpcenter/s/article/Reddit-Pixel-Advanced-Matching
	wpm.redditInitEvent = () => {
		try {
			if (
				(
					wpmDataLayer.general.user_logged_in
					|| "order_received_page" === wpmDataLayer?.shop?.page_type
				)
				&& wpmDataLayer?.pixels?.reddit?.advanced_matching
			) {
				let data = {
					optOut                  : false,
					useDecimalCurrencyValues: true,
				}

				if (wpmDataLayer?.user?.email?.sha256) {
					data.email = wpmDataLayer.user.email.sha256
				}

				if (wpmDataLayer?.user?.id?.sha256) {
					data.externalId = wpmDataLayer.user.id.sha256
				}

				rdt("init", wpm.getRedditAdvertiserId(), data)

			} else {
				rdt("init", wpm.getRedditAdvertiserId())
			}
		} catch (e) {
			console.error(e)
		}
	}

	wpm.loadRedditPixel = function () {

		try {
			wpmDataLayer.pixels.reddit.loaded = true

			// @formatter:off
			!function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);

			wpm.redditInitEvent();
			rdt('track', 'PageVisit');
			// @formatter:on

		} catch (e) {
			console.error(e)
		}
	}

}(window.wpm = window.wpm || {}, jQuery));
