/**
 * Copyright 2024 SweetCode. All rights reserved.
 *
 * Load Taboola Ads functions
 * */

(function (wpm, $, undefined) {

	wpm.loadTaboolaPixel = () => {

		const taboolaId = wpmDataLayer.pixels.taboola.account_id

		try {

			// @formatter:off
				window._tfa = window._tfa || [];
				window._tfa.push({notify: 'event', name: 'page_view', id: taboolaId});
				!function (t, f, a, x) {
				if (!document.getElementById(x)) {
				t.async = 1;t.src = a;t.id=x;f.parentNode.insertBefore(t, f);
			}
			}(document.createElement('script'),
				document.getElementsByTagName('script')[0],
				`//cdn.taboola.com/libtrc/unip/${taboolaId}/tfa.js`,
				'tb_tfa_script');
			// @formatter:on

			wpmDataLayer.pixels.taboola.loaded = true

		} catch (e) {
			console.error(e)
		}
	}

}(window.wpm = window.wpm || {}, jQuery));
